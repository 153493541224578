<template>
  <section>
    <b-card>
      <BrunaForm
        ref="reasigRef"
        :fields="this.asginado.fields"
        :card="this.asginado.card"
        @change="priceChange"
      ></BrunaForm>
      <ag-grid-table
        :configUrl="configAsignado"
        :dataUrl="dataAsignado"
        :editUrl="editAsignado"
        class="teste"
      ></ag-grid-table>
    </b-card>
    <b-card>
      <BrunaForm
        ref="reasigRef"
        :fields="this.reemplazo.fields"
        :card="this.reemplazo.card"
        @change="priceChange"
      ></BrunaForm>
      <ag-grid-table
        :configUrl="configReemplazo"
        :dataUrl="dataReemplazo"
        :editUrl="editReemplazo"
        class="teste"
      ></ag-grid-table>
      <b-button
        variant="primary"
        class="mt-4 float-right"
        @click="save"
        size="lg"
        >Guardar</b-button
      >
    </b-card>
  </section>
</template>

<script>
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";
import useApiServices from "@/services/useApiServices.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";

import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BModal,
  BCardText,
  VBTooltip,
  VBPopover,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    VBPopover,
    VBTooltip,
    BrunaForm,
    BrunaFormRepeat,
    BModal,
    BCardText,
    AgGridTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  data() {
    return {
      configAsignado: useApiServices.reasignarLoteAsignadoConfig,
      dataAsignado: useApiServices.reasignarLoteAsignadoData,
      editAsignado: useApiServices.reasignarLoteAsignadoEdit,
      configReemplazo: useApiServices.reasignarLoteReemplazoConfig,
      dataReemplazo: useApiServices.reasignarLoteReemplazoData,
      editReemplazo: useApiServices.reasignarLoteReemplazoEdit,
      asginado: {
        card: {
          title: "Lote Asignado por Bruna",
          subtitle: "",
          tooltip: "",
        },
        fields: [
          {
            label: "",
            type: "select",
            id: "asignado",
            rules: "required",
            disabled: "disabled",
            options: [{ value: null, text: "126863" }],
            format: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
            value: null,
          },
        ],
      },
      reemplazo: {
        card: {
          title: "Seleccionar Lote Reemplazo",
          subtitle: "",
          tooltip: "",
        },
        fields: [
          {
            label: "",
            type: "select",
            id: "reemplazo",
            rules: "required",
            options: [
              { value: null, text: "Selecciona" },
              { value: 3, text: "BSHT" },
            ],
            format: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            },
          },
        ],
      },
    };
  },
  methods: {
    save() {
      let data = this.asginado.fields.reduce((acumulador, field) => {
        acumulador[field.id] = field.value;
        return acumulador;
      }, {});
      console.log({ data });
      let self = this;
      Promise.all([this.$refs.reasigRef.validate()]).then(([priceValid]) => {
        if (priceValid) {
          this.$bvModal
            .msgBoxConfirm(
              "Desea reemplanzar el lote Ejemplo por el lote BSHT en el contrato 126863?",
              {
                title: "Aviso Importante",
                centered: true,
                okTitle: "Reemplazar",
                cancelTitle: "Cancelar",
              }
            )
            .then((value) => {
              if (value) {
                useApiServices
                  .postPreciosBrasilCreate(data, this)
                  .then((response) => {
                    console.log(response);
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Carga Exitosa`,
                        icon: "UploadIcon",
                        variant: "success",
                        html: `Reemplazo Realizado con exito`,
                      },
                    });
                  })
                  .catch((error) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Ocurrio un Error`,
                        icon: "UploadIcon",
                        variant: "danger",
                        html: `Ha ocurrido un error al reemplazar`,
                      },
                    });
                  });
              }
            });
        }
      });
    },
    priceChange(data) {
      this.form_values.price = data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.teste {
  margin-top: -5rem;
}
</style>
